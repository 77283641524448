import axios from "axios";
//dev
//const API_URL = "http://localhost:7006/api";
//prod
const API_URL = "https://bot.lemonaitech.com/api";
export const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

const clientService = {
  getClients: async () => {
    try {
      const response = await axios.get(`${API_URL}/clients`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching clients:", error);
      throw error;
    }
  },

  getClient: async (clientId) => {
    try {
      const response = await axios.get(`${API_URL}/clients/${clientId}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching client:", error);
      throw error;
    }
  },

  createClient: async (clientData) => {
    try {
      const response = await axios.post(`${API_URL}/clients`, clientData, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error("Error creating client:", error);
      throw error;
    }
  },

  updateClient: async (clientId, clientData) => {
    try {
      const response = await axios.put(
        `${API_URL}/clients/${clientId}`,
        clientData,
        {
          headers: getAuthHeader(),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating client:", error);
      throw error;
    }
  },
  deactivateClient: async (clientId) => {
    const response = await axios.post(
      `${API_URL}/clients/${clientId}/deactivate`,
      null,
      {
        headers: getAuthHeader(),
      }
    );
    return response.data;
  },

  activateClient: async (clientId) => {
    const response = await axios.post(
      `${API_URL}/clients/${clientId}/activate`,
      null,
      {
        headers: getAuthHeader(),
      }
    );
    return response.data;
  },
};

export default clientService;
