import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";
import campaignService from "../../services/campaignService";
import verticalService from "../../services/verticalService";
import RichTextEditor from "../common/RichTextEditor";
import { processContent } from "../../utils/contentProcessor";
import EmailPreviewModal from "../common/EmailPreviewModal";
import emailCredentialService from "../../services/emailCredentialService";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { es } from "date-fns/locale";

const CampaignTemplateEditor = () => {
  const { clientId, campaignId, templateId } = useParams();
  const navigate = useNavigate();
  const [template, setTemplate] = useState({
    subject: "",
    body: "",
    vertical_ids: [],
    templateType: "presentation",
    num_days_without_response: "",
    after_rejection: false,
    datetime_start: null,
  });
  const [verticals, setVerticals] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newVertical, setNewVertical] = useState({ name_vertical: "" });
  const [openPreview, setOpenPreview] = useState(false);
  const [emailCredential, setEmailCredential] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "error",
    persistent: false,
  });
  const [hasInvalidTags, setHasInvalidTags] = useState(false);

  const validTags = [
    "[SUBVERTICAL]",
    "[SUBVERTICAL(s)]",
    "[NOMBRE_EMPRESA]",
    "[NOMBRE_DESTINATARIO]",
  ];

  const validateAndReplaceContent = (content) => {
    const bracketRegex = /\[(.*?)\]/g;
    let matches = content.match(bracketRegex);
    let invalidTags = [];
    let newContent = content;

    if (matches) {
      matches.forEach((match) => {
        if (!validTags.includes(match)) {
          invalidTags.push(match);
          newContent = newContent.replace(match, validTags[0]);
        }
      });
    }

    if (invalidTags.length > 0) {
      setHasInvalidTags(true);
      setNotification({
        open: true,
        message: `Tags no válidos detectados: ${invalidTags.join(
          ", "
        )}. Tags válidos: ${validTags.join(
          ", "
        )}. No se puede guardar la plantilla con tags inválidos.`,
        severity: "error",
        persistent: true,
      });
      return newContent;
    }

    setHasInvalidTags(false);
    setNotification((prev) => ({
      ...prev,
      open: false,
      persistent: false,
    }));
    return content;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const verticalsData = await verticalService.getClientVerticals(
          clientId
        );
        setVerticals(verticalsData);

        if (templateId) {
          const templateData = await campaignService.getCampaignTemplate(
            clientId,
            campaignId,
            templateId
          );
          setTemplate({
            subject: templateData.subject || "",
            body: templateData.body || "",
            vertical_ids: templateData.vertical_ids
              ? templateData.vertical_ids.map((id) => id.toString())
              : [],
            templateType: templateData.after_rejection
              ? "rejection"
              : templateData.num_days_without_response
              ? "followup"
              : "presentation",
            num_days_without_response:
              templateData.num_days_without_response || "",
            after_rejection: templateData.after_rejection || false,
            datetime_start: templateData.datetime_start,
          });
        }
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };

    fetchData();
  }, [clientId, campaignId, templateId]);

  useEffect(() => {
    const fetchEmailCredential = async () => {
      try {
        const credentials = await emailCredentialService.getEmailCredentials(
          clientId
        );
        if (credentials.length > 0) {
          setEmailCredential(credentials[0]);
        }
      } catch (error) {
        console.error("Error al obtener credencial de email:", error);
      }
    };

    fetchEmailCredential();
  }, [clientId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTemplate({ ...template, [name]: value });
  };

  const handleTemplateTypeChange = (e) => {
    const type = e.target.value;
    setTemplate({
      ...template,
      templateType: type,
      num_days_without_response: type === "followup" ? "" : null,
      after_rejection: type === "rejection",
    });
  };

  const handleBodyChange = (content) => {
    const validatedContent = validateAndReplaceContent(content);
    setTemplate({ ...template, body: validatedContent });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (hasInvalidTags) {
      setNotification({
        open: true,
        message:
          "No se puede guardar la plantilla mientras existan tags inválidos",
        severity: "error",
      });
      return;
    }

    try {
      const processedBody = await processContent(template.body, clientId);

      const templateData = {
        subject: template.subject,
        body: processedBody,
        vertical_ids: template.vertical_ids,
        num_days_without_response:
          template.templateType === "followup"
            ? parseInt(template.num_days_without_response)
            : null,
        after_rejection: template.templateType === "rejection",
        datetime_start: template.datetime_start,
      };

      if (templateId) {
        await campaignService.updateCampaignTemplate(
          clientId,
          campaignId,
          templateId,
          templateData
        );
      } else {
        await campaignService.createCampaignTemplate(
          clientId,
          campaignId,
          templateData
        );
      }
      navigate(`/clients/${clientId}/campaigns/${campaignId}/templates`);
    } catch (error) {
      console.error("Error al guardar plantilla:", error);
    }
  };

  const handleCreateVertical = async () => {
    try {
      const createdVertical = await verticalService.createClientVertical(
        clientId,
        { name_vertical: newVertical.name_vertical }
      );
      setVerticals([...verticals, createdVertical]);
      setTemplate({
        ...template,
        vertical_ids: [...template.vertical_ids, createdVertical.id],
      });
      setOpenModal(false);
      setNewVertical({ name_vertical: "" });
    } catch (error) {
      console.error("Error al crear vertical:", error);
    }
  };

  const handlePreview = () => {
    setOpenPreview(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ maxWidth: 800, margin: "auto", p: 2 }}
      >
        <Typography variant="h4" gutterBottom>
          {templateId ? "Editar Plantilla" : "Crear Plantilla"}
        </Typography>

        <FormControl fullWidth margin="normal">
          <InputLabel>Tipo de Plantilla</InputLabel>
          <Select
            name="templateType"
            value={template.templateType}
            onChange={handleTemplateTypeChange}
            required
          >
            <MenuItem value="presentation">Presentación</MenuItem>
            <MenuItem value="followup">Seguimiento</MenuItem>
            <MenuItem value="rejection">Rechazo</MenuItem>
          </Select>
        </FormControl>

        {template.templateType === "followup" && (
          <TextField
            fullWidth
            label="Días de espera para seguimiento"
            name="num_days_without_response"
            type="number"
            value={template.num_days_without_response}
            onChange={handleChange}
            margin="normal"
            required
          />
        )}

        <TextField
          fullWidth
          label="Asunto"
          name="subject"
          value={template.subject}
          onChange={handleChange}
          margin="normal"
          required
        />

        {(!templateId || template.datetime_start === null) && (
          <DateTimePicker
            label="Fecha de inicio"
            value={template.datetime_start}
            onChange={(newValue) => {
              setTemplate((prev) => ({
                ...prev,
                datetime_start: newValue,
              }));
            }}
            slotProps={{ textField: { fullWidth: true, margin: "normal" } }}
            sx={{ mt: 2, mb: 2 }}
          />
        )}

        <FormControl fullWidth margin="normal">
          <InputLabel>Verticales</InputLabel>
          <Select
            multiple
            name="vertical_ids"
            value={template.vertical_ids.map((id) => id.toString())}
            onChange={(e) => {
              const selectedIds = e.target.value;
              setTemplate((prev) => ({
                ...prev,
                vertical_ids: selectedIds,
              }));
            }}
            required
          >
            {verticals.map((vertical) => (
              <MenuItem key={vertical.id} value={vertical.id.toString()}>
                {vertical.name_vertical}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Cuerpo del Email
          </Typography>
          <RichTextEditor
            value={template.body}
            onChange={handleBodyChange}
            clientId={clientId}
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          disabled={hasInvalidTags}
        >
          {templateId ? "Actualizar Plantilla" : "Crear Plantilla"}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handlePreview}
          sx={{ mt: 2, ml: 2 }}
        >
          Vista Previa
        </Button>

        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
          <DialogTitle>Crear Nuevo Vertical</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Nombre del Vertical"
              value={newVertical.name_vertical}
              onChange={(e) =>
                setNewVertical({ name_vertical: e.target.value })
              }
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
            <Button onClick={handleCreateVertical} variant="contained">
              Crear
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <EmailPreviewModal
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        signature={emailCredential?.signature || ""}
        body={template.body}
        textClosing={emailCredential?.text_closing || ""}
        clientId={clientId}
      />
      <Snackbar
        open={notification.open}
        autoHideDuration={notification.persistent ? null : 6000}
        onClose={() => setNotification((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setNotification((prev) => ({ ...prev, open: false }))}
          severity={notification.severity}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </LocalizationProvider>
  );
};

export default CampaignTemplateEditor;
