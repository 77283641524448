import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import campaignService from "../../services/campaignService";
import EmailPreviewModal from "../common/EmailPreviewModal";
import MultiSelect from "../common/MultiSelect";
import emailCredentialService from "../../services/emailCredentialService";
import { es } from "date-fns/locale";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format, endOfMonth, startOfMonth } from "date-fns";

const MonthlyReportDialog = ({ open, onClose, onGenerate, isLoading }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const handleGenerate = () => {
    const startDate = startOfMonth(selectedMonth);
    const endDate = endOfMonth(selectedMonth);
    onGenerate({ startDate, endDate });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Seleccionar Mes para el Reporte</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <DatePicker
            label="Mes"
            value={selectedMonth}
            onChange={setSelectedMonth}
            views={["month", "year"]}
            format="MMMM yyyy"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={handleGenerate}
          variant="contained"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : "Generar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CampaignStats = () => {
  const { clientId, campaignId } = useParams();
  const [stats, setStats] = useState([]);
  const [filteredStats, setFilteredStats] = useState([]);
  const [filters, setFilters] = useState({
    company: "",
    type: [],
    response: [],
  });
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });
  const [showOnlyWithResponse, setShowOnlyWithResponse] = useState(false);
  const [openEmailPreview, setOpenEmailPreview] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [openResponseDialog, setOpenResponseDialog] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [emailCredential, setEmailCredential] = useState(null);
  const [sortDirection, setSortDirection] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);
  const [openMonthSelector, setOpenMonthSelector] = useState(false);

  const templateTypeOptions = [
    { value: "presentation", label: "Presentación" },
    { value: "followup", label: "Seguimiento" },
    { value: "rejection", label: "Rechazo" },
  ];

  const responseTypeOptions = [
    { value: "AUTOMATIC", label: "Respuesta Automática" },
    { value: "INTERESTED", label: "Interesado" },
    { value: "NOT_INTERESTED", label: "No Interesado" },
  ];

  const handleGenerateReport = async ({ startDate, endDate }) => {
    setIsLoading(true);
    try {
      await campaignService.generateCredentialsReport(
        clientId,
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd")
      );
      setOpenMonthSelector(false);
    } catch (error) {
      console.error("Error al generar el reporte:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTemplateTypeLabel = (type) => {
    const typeMap = {
      presentation: "Presentación",
      followup: "Seguimiento",
      rejection: "Rechazo",
    };
    return typeMap[type] || type;
  };

  const getResponseTypeLabel = (type) => {
    const responseMap = {
      AUTOMATIC: "Respuesta Automática",
      INTERESTED: "Interesado",
      NOT_INTERESTED: "No Interesado",
    };
    return responseMap[type] || "-";
  };

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await campaignService.getCampaignEmailStats(
          clientId,
          campaignId
        );
        setStats(response.stats);
        setFilteredStats(response.stats);
      } catch (error) {
        console.error("Error fetching stats:", error);
      }
    };
    fetchStats();
  }, [clientId, campaignId]);

  useEffect(() => {
    let filtered = [...stats];

    if (dateRange.start) {
      filtered = filtered.filter(
        (stat) => new Date(stat.datetime_insert) >= dateRange.start
      );
    }
    if (dateRange.end) {
      filtered = filtered.filter(
        (stat) => new Date(stat.datetime_insert) <= dateRange.end
      );
    }

    if (filters.company) {
      filtered = filtered.filter((stat) =>
        stat.company_name.toLowerCase().includes(filters.company.toLowerCase())
      );
    }
    if (filters.type.length > 0) {
      filtered = filtered.filter((stat) =>
        filters.type.includes(stat.template_type)
      );
    }
    if (filters.response.length > 0) {
      filtered = filtered.filter((stat) =>
        filters.response.includes(stat.type_response)
      );
    }

    if (showOnlyWithResponse) {
      filtered = filtered.filter((stat) => stat.response !== null);
    }

    setFilteredStats(filtered);
    setPage(0);
  }, [stats, filters, dateRange, showOnlyWithResponse]);

  useEffect(() => {
    const fetchEmailCredential = async () => {
      try {
        const credentials = await emailCredentialService.getEmailCredentials(
          clientId
        );
        if (credentials.length > 0) {
          setEmailCredential(credentials[0]);
        }
      } catch (error) {
        console.error("Error al cargar las credenciales de email:", error);
      }
    };

    fetchEmailCredential();
  }, [clientId]);

  const handleViewEmail = (email) => {
    setSelectedEmail(email);
    setOpenEmailPreview(true);
  };

  const handleViewResponse = (response) => {
    setSelectedResponse(response);
    setOpenResponseDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortByDate = () => {
    setSortDirection((prev) => (prev === "desc" ? "asc" : "desc"));
    setFilteredStats((prev) =>
      [...prev].sort((a, b) => {
        const dateA = new Date(a.datetime_insert);
        const dateB = new Date(b.datetime_insert);
        return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
      })
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Cambiar a columna en pantallas pequeñas
            alignItems: { xs: "flex-start", md: "center" },
            justifyContent: "space-between",
            mb: 2,
            gap: 2, // Separar elementos verticalmente en pantallas pequeñas
          }}
        >
          <Typography variant="h4" gutterBottom>
            Estadísticas de Campaña
          </Typography>
          <Button
            variant="outlined"
            size="medium"
            sx={{
              minWidth: "100px",
              py: 1,
              px: 2,
              fontSize: "1rem",
              height: "40px",
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => setOpenMonthSelector(true)}
            disabled={isLoading}
          >
            Generar Report
          </Button>
        </Box>

        <MonthlyReportDialog
          open={openMonthSelector}
          onClose={() => setOpenMonthSelector(false)}
          onGenerate={handleGenerateReport}
          isLoading={isLoading}
        />
        <Box
          sx={{
            mb: 3,
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <TextField
            label="Filtrar por empresa"
            value={filters.company}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, company: e.target.value }))
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "40px",
              },
              "& .MuiInputLabel-root": {
                transform: "translate(14px, 8px) scale(1)",
                "&.Mui-focused, &.MuiFormLabel-filled": {
                  transform: "translate(14px, -9px) scale(0.75)",
                },
              },
            }}
          />
          <MultiSelect
            options={templateTypeOptions}
            value={filters.type}
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, type: value }))
            }
            label="Tipo de email"
            placeholder="Todos los tipos"
            height="40px"
          />
          <MultiSelect
            options={responseTypeOptions}
            value={filters.response}
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, response: value }))
            }
            label="Tipo de respuesta"
            placeholder="Todas las respuestas"
            height="40px"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showOnlyWithResponse}
                onChange={(e) => setShowOnlyWithResponse(e.target.checked)}
                size="small"
              />
            }
            label="Solo con respuesta"
            sx={{ m: 0 }}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  onClick={handleSortByDate}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f5f5f5" },
                    position: "relative",
                    pr: 4, // Padding right para dejar espacio al icono
                    minWidth: "120px", // Asegura espacio mínimo para móviles
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Fecha
                    <Box
                      sx={{
                        position: "absolute",
                        right: 8,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {sortDirection === "desc" ? (
                        <KeyboardArrowDownIcon sx={{ fontSize: "1.2rem" }} />
                      ) : (
                        <KeyboardArrowUpIcon sx={{ fontSize: "1.2rem" }} />
                      )}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>Empresa</TableCell>
                <TableCell>Tipo de email</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Tipo de respuesta</TableCell>
                <TableCell>Respuesta</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredStats
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((stat, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      height: "32px", // Reduced from 40px
                    }}
                  >
                    <TableCell sx={{ py: 0.5 }}>
                      {" "}
                      {/* Reduced vertical padding */}
                      {new Date(stat.datetime_insert).toLocaleString()}
                    </TableCell>
                    <TableCell sx={{ py: 0.5 }}>{stat.company_name}</TableCell>
                    <TableCell sx={{ py: 0.5 }}>
                      {getTemplateTypeLabel(stat.template_type)}
                    </TableCell>
                    <TableCell sx={{ py: 0.5 }}>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          minWidth: "auto",
                          py: 0.25,
                          px: 1,
                          fontSize: "0.75rem",
                          height: "24px",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => handleViewEmail(stat)}
                      >
                        Ver email
                      </Button>
                    </TableCell>
                    <TableCell sx={{ py: 0.5 }}>
                      {getResponseTypeLabel(stat.type_response)}
                    </TableCell>
                    <TableCell sx={{ py: 0.5 }}>
                      {stat.response ? (
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{
                            minWidth: "auto",
                            py: 0.25,
                            px: 1,
                            fontSize: "0.75rem",
                            height: "24px",
                            whiteSpace: "nowrap",
                          }}
                          onClick={() => handleViewResponse(stat.response)}
                        >
                          Ver respuesta
                        </Button>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={filteredStats.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
            sx={{
              ".MuiTablePagination-select": {
                py: 0.5,
              },
            }}
          />
        </TableContainer>

        <EmailPreviewModal
          open={openEmailPreview}
          onClose={() => setOpenEmailPreview(false)}
          subject={selectedEmail?.subject || ""}
          body={selectedEmail?.body || ""}
          clientId={clientId}
          processSignature={false}
        />

        <Dialog
          open={openResponseDialog}
          onClose={() => setOpenResponseDialog(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>{selectedResponse?.subject || ""}</DialogTitle>
          <DialogContent>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              {selectedResponse?.body_raw || ""}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenResponseDialog(false)}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </LocalizationProvider>
  );
};

export default CampaignStats;
