import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Switch,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import clientService from "../../services/clientService";

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showActiveOnly, setShowActiveOnly] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isDeactivating, setIsDeactivating] = useState(false);

  const fetchClients = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await clientService.getClients();
      setClients(data);
    } catch (error) {
      setError(
        error.response?.data?.message || "Error al obtener los clientes"
      );
      console.error("Error al obtener los clientes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleToggleStatus = (client, event) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedClient(client);
    setIsDeactivating(!client.datetime_inactive);
    setDialogOpen(true);
  };

  const handleConfirmStatusChange = async () => {
    try {
      if (isDeactivating) {
        await clientService.deactivateClient(selectedClient.id);
      } else {
        await clientService.activateClient(selectedClient.id);
      }
      await fetchClients();
      setDialogOpen(false);
    } catch (error) {
      console.error("Error updating client status:", error);
      setError("Error al actualizar el estado del cliente");
    }
  };

  const navigateToClient = (e, clientId) => {
    if (e.target.tagName !== "INPUT") {
      // No navegar si se hace click en el Switch
      window.location.href = `/clients/${clientId}`;
    }
  };

  const filteredClients = showActiveOnly
    ? clients.filter((client) => !client.datetime_inactive)
    : clients;

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 1200, margin: "24px auto", p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ margin: "auto", p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Clientes
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Button
          component={Link}
          to="/clients/new"
          variant="contained"
          color="primary"
        >
          Crear Nuevo Cliente
        </Button>

        <FormControlLabel
          control={
            <Checkbox
              checked={showActiveOnly}
              onChange={(e) => setShowActiveOnly(e.target.checked)}
            />
          }
          label="Mostrar solo clientes activos"
        />
      </Box>

      {filteredClients.length === 0 ? (
        <Typography variant="body1" sx={{ textAlign: "center", mt: 4 }}>
          No se encontraron clientes. ¡Crea tu primer cliente!
        </Typography>
      ) : (
        <List>
          {filteredClients
            .sort((a, b) => a.id - b.id)
            .map((client) => (
              <ListItem
                key={client.id}
                onClick={(e) => navigateToClient(e, client.id)}
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: 1,
                  mb: 2,
                  p: 2,
                  backgroundColor: "background.paper",
                  color: "inherit",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "action.hover",
                    transition: "background-color 0.3s",
                  },
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      "& > *": { flexShrink: 0 },
                    }}
                  >
                    <Typography component="span" variant="subtitle1">
                      #{client.id}
                    </Typography>
                    <Typography component="span" variant="subtitle1">
                      {client.name}
                    </Typography>
                    <Box sx={{ ml: "auto" }}>
                      <Switch
                        checked={!client.datetime_inactive}
                        onChange={(e) => handleToggleStatus(client, e)}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography component="span" variant="body2">
                      Total de Correos Enviados: {client.emails_sent || 0}
                    </Typography>
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        ml: 2,
                        color: client.datetime_inactive
                          ? "error.main"
                          : "success.main",
                      }}
                    >
                      • {client.datetime_inactive ? "Inactivo" : "Activo"}
                    </Typography>
                  </Box>
                </Box>
                <Button
                  component={Link}
                  to={`/clients/${client.id}`}
                  variant="outlined"
                  color="primary"
                  onClick={(e) => e.stopPropagation()}
                  sx={{ ml: 2 }}
                >
                  Ver Detalles
                </Button>
              </ListItem>
            ))}
        </List>
      )}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Confirmar acción</DialogTitle>
        <DialogContent>
          {selectedClient && (
            <Typography>
              {isDeactivating
                ? `¿Quieres desactivar el cliente "${selectedClient.name}"?`
                : `¿Quieres activar el cliente "${selectedClient.name}"?`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancelar</Button>
          <Button onClick={handleConfirmStatusChange} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ClientList;
