import axios from "axios";
//dev
//const API_URL = "http://localhost:7006/api";
//prod
const API_URL = "https://bot.lemonaitech.com/api";
export const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};
const fileService = {
  uploadFile: async (clientId, file, fileName, mimeType) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name_file", fileName);
    formData.append("mime_type", mimeType);

    const response = await axios.post(
      `${API_URL}/clients/${clientId}/files`,
      formData,
      {
        headers: {
          ...getAuthHeader(),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  },

  getFile: async (clientId, fileId) => {
    const response = await axios.get(
      `${API_URL}/clients/${clientId}/files/${fileId}`,
      {
        headers: getAuthHeader(),
        responseType: "blob",
      }
    );
    return response.data;
  },
};

export default fileService;
