import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Box,
  Switch,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import campaignService from "../../services/campaignService";

const CampaignList = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [showActiveOnly, setShowActiveOnly] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isFinishing, setIsFinishing] = useState(false);

  useEffect(() => {
    fetchCampaigns();
  }, [clientId]);

  const fetchCampaigns = async () => {
    try {
      const campaignsData = await campaignService.getCampaigns(clientId);
      setCampaigns(campaignsData);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const handleCampaignClick = (campaignId) => {
    navigate(`/clients/${clientId}/campaigns/${campaignId}/templates`);
  };

  const handleToggleStatus = (campaign, event) => {
    event.stopPropagation();
    setSelectedCampaign(campaign);
    setIsFinishing(!campaign.datetime_end);
    setDialogOpen(true);
  };

  const handleConfirmStatusChange = async () => {
    try {
      if (isFinishing) {
        await campaignService.finishCampaign(clientId, selectedCampaign.id);
      } else {
        await campaignService.resumeCampaign(clientId, selectedCampaign.id);
      }
      await fetchCampaigns();
      setDialogOpen(false);
    } catch (error) {
      console.error("Error updating campaign status:", error);
    }
  };

  const filteredCampaigns = showActiveOnly
    ? campaigns.filter((campaign) => !campaign.datetime_end)
    : campaigns;

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Campañas
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Button
          component={Link}
          to={`/clients/${clientId}/campaigns/new`}
          variant="contained"
          color="primary"
        >
          Crear Nueva Campaña
        </Button>

        <FormControlLabel
          control={
            <Checkbox
              checked={showActiveOnly}
              onChange={(e) => setShowActiveOnly(e.target.checked)}
            />
          }
          label="Mostrar solo campañas activas"
        />
      </Box>

      <List>
        {filteredCampaigns.map((campaign) => (
          <ListItem
            key={campaign.id}
            sx={{
              cursor: "pointer",
              border: "1px solid #ddd",
              borderRadius: 1,
              mb: 2,
              p: 2,
              backgroundColor: "background.paper",
              "&:hover": {
                backgroundColor: "action.hover",
              },
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "stretch", sm: "center" },
            }}
            onClick={() => handleCampaignClick(campaign.id)}
          >
            <ListItemText
              primary={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {campaign.name_campaign}
                  <Switch
                    checked={!campaign.datetime_end}
                    onChange={(e) => handleToggleStatus(campaign, e)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Box>
              }
              secondary={
                <>
                  Fecha de inicio: {campaign.datetime_start || "No iniciada"}
                  <br />
                  Estado: {campaign.datetime_end ? "Finalizada" : "Activa"}
                  <br />
                  Correos enviados: {campaign.emails_sent} (
                  {campaign.unique_contacts} contactos únicos,{" "}
                  {campaign.total_companies} empresas)
                </>
              }
            />
            <Box
              onClick={(e) => e.stopPropagation()}
              sx={{
                display: "flex",
                gap: 1,
                mt: { xs: 2, sm: 0 },
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Button
                component={Link}
                to={`/clients/${clientId}/campaigns/${campaign.id}/stats`}
                variant="outlined"
                color="primary"
              >
                Estadísticas
              </Button>
              <Button
                component={Link}
                to={`/clients/${clientId}/campaigns/${campaign.id}/templates`}
                variant="outlined"
                color="primary"
              >
                Ver Plantillas
              </Button>
            </Box>
          </ListItem>
        ))}
      </List>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Confirmar acción</DialogTitle>
        <DialogContent>
          {selectedCampaign && (
            <Typography>
              {isFinishing
                ? `¿Quieres finalizar la campaña "${selectedCampaign.name_campaign}"?`
                : `¿Quieres reanudar la campaña "${selectedCampaign.name_campaign}"?`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancelar</Button>
          <Button onClick={handleConfirmStatusChange} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CampaignList;
